:root {
    --red: #FF5252;
    --black: #231f20;
    --gray: #929292;
}

a {
    color: #fff;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999999;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.inner.one {
    left: 45%;
    top: 45%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid red;
    transform: translate(-50%, -50%);
}

.inner.two {
    left: 45%;
    top: 45%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid red;
    transform: translate(-50%, -50%);
}

.inner.three {
    left: 45%;
    top: 45%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid red;
    transform: translate(-50%, -50%);
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

html {
    scroll-behavior: smooth;
}

body, html {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
}

.backToTop {
    background-color: var(--red);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border:3px solid #fff;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
    cursor: pointer;
}

.backToTop a {
    width: 100%;
    height: 100%;
}

.backToTop i {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
}

.container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../images/background.jpeg");
    background-size: cover;
    background-position: center;
    position: relative;
}

.color-opacity {
    background-color: #FF5252;
}

.color-no-opacity {
    background-color: #FF5252;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FF5252;
    width: 100%;
    height: 75px;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menub {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FF5252;
    width: 100%;
    height: 75px;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logo-image {
    margin-top: 80px;
    height: 120px;
}

.menu-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu-links a {
    padding: 10px;
    margin: 5px;
    text-decoration: none;
    color: #fff;
    border-radius: 10px;
    transition: .4s ease;
    font-weight: bold;
}

.menu-links a:hover {
    background-color: #fff;
    color: #FF5252;
}

.content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.content-text h1 {
    font-size: 3rem;
}

.content-text p {
    font-size: 1.1rem;
    color: #fff;
}

.content-text {
    width: 50%;
    margin-left: 100px;
    color: #fff;
}

.image {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img {
    height: 60%;
}

.buttons {
    display: flex;
    margin-top: 60px;
    font-weight: bold;
}

.buttons i {
    font-size: 1.3rem;
    padding-left: 20px;
}

.button1 {
    height: 50px;
    background-color: var(--red);
    width: 200px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: .4s ease;
    cursor: pointer;
    border: 2px solid transparent;
    font-weight: bold;
    text-decoration: none;
}

.button1:hover {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
}

.button2 {
    margin-left: 10px;
    height: 50px;
    background-color: transparent;
    width: 220px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: .4s ease;
    cursor: pointer;
    border: 2px solid #fff;
    text-decoration: none;
}

.button2:hover {
    background-color: #fff;
    border: 2px solid transparent;
    color: var(--red);
}

.container-b {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.overviewContainer {
    width: 100%;
    /* background-image: linear-gradient(to bottom right, black, var(--red)); */
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.8), rgba(255, 0, 0, 0)), url('../images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
}

.overview .border {
    background-color: #fff;
    margin: 20px;
}

.overview {
    text-align: center;
    width: 60%;
    color: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.border {
    margin: auto;
    height: 2px;
    width: 40%;
    background-color: var(--red);
}

.boxes {
    margin-top: 50px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
}

.box {
    display: grid;
    /* grid-template-rows: auto 1fr auto 1fr; */
    grid-template-rows:80px 100px 20px auto;
    border-radius: 20px;
    text-align: center;
    transition: .3s ease;
}
.box-h2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.box i {
    font-size: 3rem;
    padding-top: 10px;
}

.box:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: var(--red);
    color: #fff;
}

.box:hover > .border {
    background-color: #fff;
}

.box p {
    font-weight: bold;
}

.container-c {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}

.download-image {
    background: url('../images/b.png');
    background-size: cover;
    background-position: center;
    transition: .4s ease;
}

/* .download-image:hover {
    background: url('../images/c.png');
    background-size: cover;
    background-position: center;
} */

.download-image img {
    width: 100%;
}

.download-text {
    display: flex;

    flex-direction: column;
    align-items: center;
}

.paragraphs {
    text-align: right;
    width: 70%;
}

.mt {
    margin-top: 30px;
}

.downloadbuttons {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.downloadbuttons img {
    width: 175px;
}

.footer {
    margin-top: 100px;
    background-color: var(--red);
}

.footer i {
    color: var(--red);
    font-size: 3rem;
    transition: .4s ease;
}

.i-wrapper {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    transition: .4s ease;
    cursor: pointer;
}

.i-wrapper:hover {
    background-color: #3a3a3a;
}

.i-wrapper:hover > i {
    color: #fff;
}

.lines {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
}

.line {
    background-color: #fff;
    height: 2px;
    width: 25%;
}

.socials {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 50px;
}

.copyright {
    text-align: center;
    font-weight: bold;
    color: #fff;
    padding-bottom: 30px;
    font-size: 1rem;
}

@media only screen and (max-width: 1350px) {
    .boxes {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .content-text h1 {
        font-size: 3rem;
    }

    .paragraphs {
        width: 100%;
    }
}

@media only screen and (max-width: 1100px) {
    .container-c {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .paragraphs {
        width: 90%;
        text-align: center;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 850px) {
    .boxes {
        grid-template-columns:1fr;
        grid-template-rows: repeat(4, auto);
    }
    .box{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .border{
        margin-bottom: 5px;
    }
    .box-h2 h2{
        margin-top:20px;
        margin-bottom:20px;
    }
    .box-h2{
        height: auto;
    }
    .overview {
        width: 80%;
    }
}

@media only screen and (max-width: 800px) {
    .content {
        flex-direction: column;
        justify-content: center;
    }

    .content-text {
        width: 90%;
        text-align: center;
        margin-top: 180px;
        margin-left: 0;
    }

    .content-text h1 {
        font-size: 1rem;
    }

    .image {
        height: 30%;
    }

    .image img {
        height: 90%;
    }

    .buttons {
        justify-content: center;
        align-items: center;
    }

    .menu {
        flex-direction: column;
        height: 100px;
    }

    .menub {
        flex-direction: column;
        height: 100px;
    }

    .logo-image {
        margin-top: 10px;
        height: 65px;
    }

    .downloadbuttons {
        flex-direction: column;
    }

    .downloadbuttons img {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 700px) {
    .socials {
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {
    .buttons {
        flex-direction: column;
        margin-top: 20px;
    }

    .button2 {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .socials {
        flex-direction: column;
    }

    .socials .i-wrapper {
        margin-bottom: 20px;
    }

    .menu-links a {
        margin: 0;
        padding: 5px;
    }
    .box-h2 h2{
        font-size: 1.3rem;
    }
    .header h1{
        text-align: center;
    }
}

.a {
    position: absolute;
    left: 0;
    bottom: 60px;
}




/*Neutral Routes*/
.neutral-pages {

}

.navigation-container {
    width: 100%;
    background-color: rgba(237, 28, 36, 1);
    height: 75px;
    padding: 0 14vw;
}

.navigation-container .logo-image-neutral {
    width: 210px;
    margin-top: -17px;
}

.neutral-pages .content-wrapper {
    min-height: -webkit-calc(100vh - 445px);
    min-height: expression(100vh - 445px);
    min-height: -moz-calc(100vh - 445px);
    min-height: -o-calc(100vh - 445px);
    min-height: calc(100vh - 430px);
}

.neutral-pages .neutral-form-container {
    padding-top: 10vh;
    width: 50%;
    margin-left: 25%;
}

.neutral-pages .neutral-form-container h3 {
    font-size: 34px;
    text-align: center;
}

.submit-form-btn {
    background-color: #FF5252;
    border: 2px solid #FF5252;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
}
.submit-form-btn:hover {
    background-color: #ffffff;
    color: rgba(237, 28, 36, 1);
}

.input-wrapper {
    margin: 20px 0;
}

.input-field input {
    width: 100%;
    padding: 10px 5px
}

.not-found-heading {
    font-size: 50px;
    text-align: center;
}

.not-found-text {
    font-size: 30px;
    text-align: center;
}

.margin-top-10 {
    margin-top: 20px;
}
.text-center {
    text-align: center;
}
.font-danger {
    color: red;
}
.padding-top-20 {
    padding-top: 20px !important;
}

.player-wrapper {
    width: 360px;
    position: relative;
}

@media only screen and (max-width: 1024px) {
    .player-wrapper {
        width: 300px;
        position: relative;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .player-wrapper {
        width: 280px;
        height: 400px;
        position: relative;
        margin-top: 25px;
    }
}
