:root {
    --white: #fff;
    --primary: #3a3a3a;
    --light: #f8f8f8;
    --muted: #909090;
    --border: #d7d7d7;
    --link: #236591;
    --button: #00365A;
    --buttonHover: #001e31;
    --icon: #8f8f8f;
    --input: #fff;
    --tableBorder: #dee2e6;
    --inputDarker: #e9ecef;
}

/* "#FF5252" "#FF5252" */
.page-item li{
    color:#fff!important;
}
.page-item{
    border:3px solid transparent;
}
.page-item.disabled .page-link,
.page-item.disabled{
    cursor:not-allowed;
}
.page-item.disabled{
}
.page-item .page-link {
    color: var(--white);
}
.page-item.active {
    background: #FF5252;
}
.block-margin-bottom-10 {
    display: block;
    margin-bottom: 10px;
}

hr {
    margin: 0;
    padding: 0;
}

.navigation-wrapper {
    height: 100vh;
    width: 100px;
    background-color: #FF5252;
    transition: .5s ease;
    z-index: 12;
}

/*.navigation-wrapper:hover {*/
/*    width: 250px;*/
/*}*/

.nav-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
}


.link-wrapper {
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    padding-top: 10px;
}

.link-wrapper a {
    color: #fff;
    text-decoration: none;
    padding-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
}

.link-border {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin-bottom: 10px;
}

.menu-bars {
    font-size: 2rem;
    margin: 20px;
    cursor: pointer;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.link a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.link i {
    margin-left: 15px;
    font-size: 4rem;
    margin-right: 10px;
    color: #fff;
}

.fa-arrow-right {
    margin-left: 20px;
    font-size: 4rem;
    color: #fff;
    margin-bottom: 30px;
}
.test{
    width: 90vw;
    text-align: center;
}
.width-90{
    width: 90vw;
}
.active{
    display: block;
}
.pagination li.d-none{
    display: none;
}
.charts {
    display: flex;
    justify-content: center;
    align-items: center;
}

.charts > * {
    width: 40% !important;
}

.table {
    width: 90vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.table:hover {
    background-color: var(--gray);
}

.column {
    width: 10%;
    height: 50px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.ban-btn {
    background-color: #FF5252 !important;
    color: #fff;
    font-weight: bold;
}

.cursor {
    cursor: pointer;
}

.images-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    margin-left: 5%;
    height: 100vh;
    z-index: 55;
    background-color: rgba(200, 200, 200, 0.6);
}

.fa-times {
    position: absolute;
    top: 20px;
    right: 5%;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 99999;
}

.pagination {
    display: flex;
    width: 50%;
    justify-content: space-around;
    text-decoration: none;
    list-style-type: none;
    margin: auto;
    margin-top: 50px;
}

.pagination li {
    background: #FF5252;
    width: 40px;
    height: 40px;
    color: #fff !important;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}
.cursor-pointer{
    cursor:pointer;
}
.margin-top-3{
    margin-top: 30px;
}
.text-center{
    text-align: center;
}
.pagination li a {
    text-decoration: none;
}

.input-container {
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    margin-bottom: 30px;
    height: 30px;
    width: 300px;
    border-radius: 30px;
    border: 2px solid #FF5252;
    text-align: center;
    outline: none;
    font-size: 1.4rem;
}

.cities {
    border-radius: 30px;
    outline: none;
    border: none;
    width: 400px;
    height: 50px;
    text-align: center;
}

.column i {
    margin-left: 10px;
}

.pagination-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status {
    cursor: pointer;
}

.status-dialog {
    width: 50%;
    height: 50%;
    position: absolute;
    background-color: rgba(255, 255, 255, .7);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
}

.status-dialog select {
    width: 200px;
    height: 50px;
    font-size: 1.4rem;
    text-align: center;
    padding-left: 20px;
    outline: none;
    border-radius: 10px;
    border: none;
    background-color: #fff;
}

.status-dialog button {
    background-color: transparent;
    width: 200px;
    height: 50px;
    border: 2px solid #FF5252;
    border-radius: 30px;
    color: #FF5252;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
}

.form-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    background-size: cover;
    color: #fff;
    text-align: center;
}

.form-container .form {
    width: 50%;
    height: 50%;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    display: grid;
    grid-template-rows: 100px 100px 1fr;
}
.react-datepicker-wrapper{
    width: 100%;
}
.inner-dialog-close i{
    color:black;
}
.dialog{

}
.form-input {
    border-radius: 7px;
    outline: none;
    border: none;
    width: 100%;
    height: 36px;
    text-align: center;
}
.dropzone{
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 50%;
    background: #fff;
}
.mr-3{
    margin-right: 30px;
}
.items{
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30%;
    height: 50%;
    margin:auto;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.border{
    border:1px dashed black ;
}
.add-new-image{
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30%;
    height: 50%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.login-input {
    border-radius: 7px;
    outline: none;
    border: none;
    width: 50%;
    height: 36px;
    text-align: center;
}
.sign-in-btn {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 40px;
    cursor: pointer;
    transition: .3s ease;
    margin: auto;
}
.addnewbtn {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 2px solid black;
    border-radius: 40px;
    cursor: pointer;
    transition: .3s ease;
}
.flex-between{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.block-images {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
}

.block-image {
    width: 26%;
}

.two-rows {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    width: 90%;
}

.form2 {
    width: 70%;
}

.expand {
    width: 90%;
}

.buttonsD {
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttonD {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border: 2px solid #FF5252;
    border-radius: 40px;
    cursor: pointer;
    transition: .3s ease;
}

.buttonD:hover {
    background-color: #FF5252;
    color: #fff;
}

.selected {
    background-color: #FF5252;
    color: #fff;
}

.fa-images {
    font-size: 2rem;
}

.add-new-user {
    position: absolute;
    top: 30px;
    right: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF5252;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.8rem;
    z-index: 33;
}

.rotated {
    transform: rotate(45deg);
}
@media only screen and (max-width: 1200px) {
    .add-new-image{
        width: 70%;
    }
}
@media only screen and (max-width: 900px) {
    .form-container .form {
        width: 90%;
        height: 70%;
        grid-template-rows: auto 1fr 1fr;
    }
    .flex-between{
        flex-direction: column;
    }

    .sign-in-btn {
        width: 90%;
    }

    .form-input {
        width: 90%;
    }
}

.field-select-search {
    color: black;
    text-align: center;
    background-color: black;
}

.dsp-flex-col {
    display: flex;
    flex-direction: column;
}

.absolute-position {
    position: absolute;
    top: 0;
    left: 0;
}
.privacy-policy-container {
    width: 80%;
    margin: auto;
}
.privacy-policy-container h1 {
    text-align: left;
}
.privacy-policy-border-bottom {
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.7)
}
.bold {
    font-weight: bold;
}
.pp-gray-bg {
    background-color: lightgray;
}
.margin-left-5 {
    margin-left: 5px;
}
.free-height {
    height: auto !important;
    padding: 12px;
}
.padding-5 {
    padding: 5px;
}
.support-text {
    width: 80%;
    color: #3a3a3a;
    margin-top: 130px;
}